import './App.css';
import { styled } from '@mui/system';
import { useState, useEffect, useRef } from 'react';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Listbox = styled('ul')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 6px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
  };
  z-index: 1;
  `,
);

const MenuItem = styled('li')(
  ({ theme }) => `
  list-style: none;
  padding: 3px;
  border-radius: 8px;
  cursor: default;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.disabled {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }
  `,
);

const Footer = styled('footer')(
  ({ theme }) => `
  background: ${grey[900]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  `
);

function Home() {
  const [flags, setFlags] = useState([]);
  const fetchFlags = useRef(true);

  const createHandleMenuClick = (menuItem) => {
    return () => {
      //localStorage.setItem('idioma', menuItem);
      window.location.href = 'https://www.mobdict.com/apl/?idioma=' + menuItem;
    };
  };

  const getFlags = async () => {
    const response = await fetch('https://mobdict.com/api/bandeiras');
    const data = await response.json();
    setFlags(data.data.bandeiras);
  };

  useEffect(() => {
    if (fetchFlags.current) {
      getFlags();
      fetchFlags.current = false;  // Ensures this effect only runs once
    }
  }, []);


  return (
    <div className="App">
      <header className="App-header">
      <svg id="logo" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 923 260">
        <path class="cls-1" d="M808.4,101.1l-70.5,5.9c-9.8.8-18.3,7.4-21.9,16.9l-5.2,13.8c-1.3,3.3-5.2,4.4-7.9,2.3l-24-13.9,21.2,39.2c4.6,8.2,13,13.2,22.1,13.2h84.4c12.5,0,23.2-9.4,25.4-22.4l3.9-23.4c2.9-17.4-10.6-33-27.4-31.6Z"/>
        <path class="cls-2" d="M97.7,104l10.7,1.5v6.8h1.8c5.1-7.3,13.2-11.7,24.3-11.7s22.3,4.8,27,13.8h2.1c4.9-8.5,13.3-13.8,25.6-13.8,19.8,0,31.9,11.2,31.9,31.3v44.5c0,1.1-1,2-2.1,1.8l-17-2.3c-1.6-.2-2.8-1.6-2.8-3.2v-38.7c0-10.8-6.9-16.5-17.1-16.5s-16.3,4.9-17.4,14.7v40c0,1.8-1.5,3.2-3.2,3.2h-15.1c-1.8,0-3.2-1.5-3.2-3.2v-38.5c0-10.8-6.4-16.2-16.8-16.2s-18,6.9-18,21.3v33.9c0,1.6-1.2,3-2.8,3.2l-17,2.3c-1.1.1-2.1-.7-2.1-1.8v-62.6c0-5.9,5.2-10.5,11.1-9.7Z"/>
        <path class="cls-2" d="M230.7,140.7c0-21.3,12.1-40.1,43.1-40.1s43.1,18.9,43.1,40.1-12.1,39.2-43.1,39.2-43.1-18.7-43.1-39.2ZM273.9,163.6c14.4,0,21.4-8.1,21.4-22.9s-7-23.8-21.4-23.8-21.4,8.5-21.4,23.8,7,22.9,21.4,22.9Z"/>
        <path class="cls-2" d="M332.6,79.6h13.1v32.8h2.1c5.5-7.2,14.4-11.2,26.4-11.2,24.9,0,35.8,17.8,35.8,39.7s-10.9,39.7-35.8,39.7-20.8-4-26.4-11.1h-2.1v1.9c0,2.7-2,4.9-4.7,5.2l-14.6,1.6c-1.2.1-2.3-.8-2.3-2.1v-88c0-4.7,3.8-8.5,8.5-8.5ZM367.1,164.2c14.5,0,21.1-8.2,21.1-23.2s-6.6-23.5-21.1-23.5-21.4,9.1-21.4,23.5,7,23.2,21.4,23.2Z"/>
        <path class="cls-2" d="M452.9,101.1c12,0,20.8,4,26.4,11.2h2.1v-32.8h13.1c4.7,0,8.5,3.8,8.5,8.5v88c0,1.2-1.1,2.2-2.3,2.1l-14.6-1.6c-2.7-.3-4.7-2.5-4.7-5.2v-1.9h-2.1c-5.5,7-14.4,11.1-26.4,11.1-24.9,0-35.8-17.7-35.8-39.7s10.9-39.7,35.8-39.7ZM460,117.4c-14.5,0-21.1,8.2-21.1,23.5s6.6,23.2,21.1,23.2,21.4-9.1,21.4-23.2-7-23.5-21.4-23.5Z"/>
        <path class="cls-2" d="M524.6,102.8v-1.9l-9.7-2.8c-3.1-.9-4.8-3.6-4.8-7v-11.4h22.8v95.2c0,1.3-1,2.4-2.3,2.4l-18.1,1.1c-.7,0-1.3-.5-1.3-1.3v-74.3h13.5Z"/>
        <path class="cls-2" d="M582.7,100.5c26.5,0,39.8,14.4,40.7,32.6h-12.7c-4.5,0-8.5-2.6-10.3-6.7-2.9-6.4-8.8-9.6-17.4-9.6-13.2,0-21.3,6.4-21.3,23.5s7.6,23.2,20.8,23.2,15.3-3.6,18-9.8c1.8-4.2,5.9-6.9,10.4-6.9h12.5c-.7,18-13.6,33.2-40.7,33.2s-42.8-18.7-42.8-39.7,12.6-39.8,42.7-39.8Z"/>
        <path class="cls-2" d="M624.4,118.3v-15.9h29.9v-1.9l-9.8-2.5c-3-1.2-4.6-3.9-4.6-7v-11.4h22.7v22.8h16v16h-16.1v40.5c0,1.6,1.3,2.9,2.9,2.9h14.4c1.9,0,3.7,1.1,4.6,2.7l6.6,12.2c.4.7-.1,1.6-1,1.6h-24.2c-19.6,0-24.9-7.2-24.9-23.1v-37h-16.5Z"/>
        <path class="cls-1" d="M785.5,95.3c-.1-.3-.1-.7,0-1.2l4.9-12.1c.5-1.2.2-1.7-.9-1.7h-1.4c-.9,0-1.4-.4-1.4-1.3v-7.6c0-.8.3-1.2,1-1.2h11.4c.7,0,1.1.4,1.1,1.1v6.8c0,.4-.1.9-.3,1.3l-7.9,15.1c-.4.7-1.3,1.2-2.2,1.2h-3.5c-.4,0-.6-.1-.8-.4Z"/>
      </svg>
          <br></br>
        <p className="text">Select a language to proceed</p>
        <Listbox>
          {flags.map((flag) => (
            <MenuItem key={flag.idioma} onClick={createHandleMenuClick(flag.idioma)}>
              <span dangerouslySetInnerHTML={{ __html: flag.bandeira }} />
              {flag.idioma}
            </MenuItem>
          ))}
        </Listbox>
      </header>
      <Footer>
        <div className='footer-info'>
          <div id="footer-social-info">
            <p className="footer-p">TECHNNI SOFTWARE DEVELOPMENT LTDA - CNPJ: 55.008.821/0001-66</p>
            <p className="footer-p">1636 Av. PAULISTA CONJ 5 PAVLH 11 SUITES 1105/230 - BELA VISTA - SAO PAULO – SP</p>
            <p className="footer-p">01310-200 - BRAZIL</p>
          </div>
          <div id="footer-contact-info">
            <p className="footer-p">Fale conosco: technni@technni.com</p>
            <p className="footer-p">Suporte: helpme@technni.com</p>
            <p className="footer-p">WhatsApp: +55 11 91054-1902</p>
          </div>
        </div>
      </Footer>
    </div>
  );
}

export default Home;
