import React from 'react';

const Support = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Suporte</h1>
      <p style={styles.paragraph}>
        Se precisar de ajuda entre em contato conosco.
      </p>
      <p style={styles.contact}>
        <strong>Suporte:</strong> helpme@technni.com
      </p>
      <p style={styles.contact}>
        <strong>WhatsApp:</strong> +55 11 91054-1902
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    maxWidth: '500px',
    margin: '0 auto',
    textAlign: 'center',
  },
  header: {
    color: '#333',
    marginBottom: '20px',
  },
  paragraph: {
    color: '#555',
    marginBottom: '10px',
  },
  contact: {
    color: '#333',
    marginBottom: '5px',
  },
  link: {
    color: '#0066cc',
    textDecoration: 'none',
  },
};

export default Support;